<template>
    <div>
          <div>
            <h3>I. Kết quả KPI</h3>
          </div>
          <div class="mb-1">
            <el-button style="background-color: rgb(93, 4, 176); border-radius: 8px; padding: 12px" class="sum-result-v1 mb-2 text-white"
              >% KPI tổng: {{ dataPersonal.total_percent_kra >= 0 && dataPersonal.total_percent_kra !== null ? dataPersonal.total_percent_kra + '%' : 'Chưa có' }}</el-button>
          </div>
          <div style="overflow: scroll; max-height: 500px; min-height: 134px">
            <div class="example-preview table-responsive">
              <div class="table-container">
                <table class="table table-vertical-center table-bordered" element-loading-text="Loading...">
                  <thead>
                    <tr style="background-color: #f8f8f8; word-break: auto-phrase">
                      <th style="width:18%">KRAs</th>
                      <th style="width:12%">Yêu cầu thành phẩm</th>
                      <th style="width:17%">Thành phẩm</th>
                      <th style="width:5%">Trọng số</th>
                      <th style="width:7%" v-for="(item, index) in dataPersonal.details[0].kra_manager_weeks">Kết quả tuần {{ index + 1 }}</th>
                      <th style="width:7%">Kết quả KRA</th>
                      <th style="width:10%">Nhận xét</th>
                    </tr>
                  </thead>
                  <tbody v-if="dataPersonal">
                    <tr v-for="(item, index) in dataPersonal.details" :key="index">
                      <td class="text-break">{{ item?.name ?? '' }}</td>
                      <td class="line_hieght_table text-break">{{ item?.request_result ?? '' }}</td>
                      <td class="line_hieght_table text-break"><span v-html="item?.results ?? ''"></span></td>
                      <td class="line_hieght_table" style="font-size: 15px">
                        {{ item?.percent_score + "%" }}
                      </td>
                      <td v-for="week in item?.kra_manager_weeks" :key="week.week">
                        <div style="position:relative;">
                          <el-select
                              :title="!item.results ? 'Không thể chấm do chưa có thành phẩm' : ''"
                              @change="handleMarkResultKpi(item, week, item?.kra_manager_weeks)"
                              :class="changeColor(week?.percent_results)"
                              :disabled="is_disable || !item.results"
                              class="input_percent border-0"
                              v-model="week.percent_results"
                              placeholder="Chọn"
                          >
                            <el-option
                                v-for="item in percents"
                                :key="item.count"
                                :label="item.value + '%'"
                                :value="item.count"
                            >
                            </el-option>
                          </el-select>
                          <div ref="popup" v-show="weekSelected?.week === week?.week && kraSelected?.id === item?.id && isShowPopup" class="popup-suggest">
                            <div style="font-weight: 700; display: flex;">Tự động điền</div>
                            <div style="word-break: break-word; white-space: nowrap">Chấm các tuần còn lại của KRA này là {{percent}}%?</div>
                            <div style="display: flex; justify-content: center; align-items: center; gap: 10px">
                              <i class="el-icon-circle-check" style="color: #0C9200; cursor: pointer; font-size: 25px;" @click="setAllValue"></i>
                              <i class="el-icon-circle-close" style="color: red; cursor: pointer; font-size: 25px;" @click="isShowPopup = false"></i>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td style="text-align: start">{{ (item?.percent_results || item?.percent_results === 0)  ? `${item?.percent_results}%` : 'Chưa có' }}</td>
                      <td>
                        <el-input id="input-comment" placeholder="Nhập nhận xét..." style="border: 0;" maxlength="200" :disabled="is_disable || !item.results" v-model="item.description" clearable>
                        </el-input>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr class="text-center">
                      <td colspan="10">Không có dữ liệu</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
    </div>
        
</template>

<script>
export default {
  components: {},
  props: {
    dataPersonal: {
      type: Object,
      default: {},
    },
    is_disable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1, 
      percents: [
        { count: 0, value: 0 },
        { count: 30, value: 30 },
        { count: 60, value: 60 },
        { count: 90, value: 90 },
        { count: 100, value: 100 },
      ],
      listWeekly: [],
      weekSelected: null,
      kraSelected: null,
      percent: null,
      isShowPopup: false,
    };
  },
  watch: {
    kraSelected: {
      handler(val) {
        this.calculatorTotalPercent(val)
      },
      deep: true,
    }
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    changeColor(count) {
      switch (count) {
        case 0:
          return 'kra-text-red';
        case 30:
        case 60:
        case 90:
          return 'kra-text-blue';
        case 100:
          return 'kra-text-green';
        default:
          return 'kra-text-black';
      }
    },
    roundDecimal(value, decimals) {
      if (value % 1 !== 0) {
        return parseFloat(value.toFixed(decimals));
      }
      return value;
    },
    setAllValue() {
      for (let i = 0; i < this.listWeekly.length; i++) {
        this.listWeekly[i].percent_results = this.percent
      }
      this.isShowPopup = false
    },
    calculatorTotalPercent(kra) {
      let totalPercent = 0
      let lengthMarked = 0
      let totalPercentUser = 0
      kra?.kra_manager_weeks.map(item => {
        if (item?.percent_results || item?.percent_results === 0) {
          totalPercent += item?.percent_results
          lengthMarked += 1
        }
      })
      kra.percent_results = this.roundDecimal((totalPercent / lengthMarked), 2)
      this.dataPersonal.details.map(item => {
        if (item?.percent_results || item?.percent_results === 0) {
          totalPercentUser += (item?.percent_results * item?.percent_score) / 100
        }
      })
      // eslint-disable-next-line vue/no-mutating-props
      this.dataPersonal.total_percent_kra = this.roundDecimal(totalPercentUser, 2)
    },
    handleMarkResultKpi(kra, week, weeks) {
      if (week.week === 1 && week.percent_results || week.week === 1 && week.percent_results === 0 || week.week === 2 && week.percent_results === weeks[0].percent_results) {
        this.kraSelected = kra
        this.weekSelected = week
        this.isShowPopup = true
        this.percent = weeks[0].percent_results
        this.listWeekly = weeks
      } else if (!weeks[0].percent_results && !weeks[1].percent_results) {
        this.isShowPopup = false
      }
      this.calculatorTotalPercent(kra)
    },
  },
 
};
</script>

<style>
input#input-comment {
    border: 0;
}
.kra-text-black input{
  color: black !important;
}
.kra-text-blue input{
  color: #0000ffc4 !important;
}
.kra-text-red input{
  color:  #FF0000 !important;
}
.kra-text-green input{
  color: #006B04!important;
}

thead th {
  position: sticky;
  top: 0;
  background-color: #f8f8f8;
  z-index: 10; /* Đảm bảo header không bị che bởi nội dung cuộn */
  text-align: center;
}

.table-container {
  max-height: 400px; /* Bạn có thể điều chỉnh chiều cao theo nhu cầu */
  overflow-y: auto;
}

.line_hieght_table {
  line-height: 1.5;
}

.styled-link {
  color: rgba(0, 102, 255, 1);
}

.styled-link:hover {
  color: rgba(0, 102, 255, 1);
  text-decoration: underline !important;
  cursor: pointer;
}
.popup-suggest {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
  position: absolute;
  top: 40px;
  left: 100px;
  min-width: 320px;
  max-width: 360px;
  padding: 10px 20px;
  border-radius: 8px;
  background: white;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  z-index: 99;
}
</style>
